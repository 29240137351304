.map-header {
  position: relative;

  width: 100%;

  img {
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: center center;
  }

  // &:hover {
  //   height: 130px;
  // }
}

.special-loader .level {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem);
  padding: 0.5rem;

  font-size: 0.8rem;

  .level-left {
    margin-bottom: 0;
  }

  strong {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.school-photo {
  object-fit: cover;
}

div {
  color: #2c3e50;
}

.delete-button {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  margin-top: 12px;
  background-color: #00000060;
  display: block;
  &:hover {
    background-color: #00000090;
    color: red;
  }
}
.select-menu-outer {
  z-index: 11;
}
