// Tooltips
@import '~@creativebulma/bulma-tooltip/src/sass/index.sass';
.school-overview-wrapper {
  [data-tooltip] {
    // Nudge tooltips over to the right on medium screen size
    @media screen and (max-width: 1450px) {
      &:before {
        word-wrap: normal !important;
        margin-left: 60px;
      }
    }
  }
}

// Slider
$slider-thumb-radius: 50%;
$slider-thumb-border: 3px solid #3f60c6;
@import '../../node_modules/bulma-slider/src/sass/index.sass';

.slick-arrow::before {
  color: #00000050 !important;
}

.slick-track {
  display: flex !important;

  .slick-slide {
    display: flex !important;
    flex: 1;
  }
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}
