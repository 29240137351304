$lineColor: hsl(0, 0%, 93%);

.no-arrows {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.sort-container {
  width: 300px;
  border-right: 1px solid $lineColor;

  .divider {
    width: calc(100% + 0.5rem);
    height: 2px;
    background-color: $lineColor;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .radio {
    display: block;
    padding-left: 35px !important;
    user-select: none;

    &:hover input ~ .custom-radio {
      background-color: #ccc;
    }
    input:checked ~ .custom-radio {
      background-color: #18af90;
    }
    input:checked ~ .custom-radio:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .custom-radio {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border-radius: 50%;
      border: 2px solid $lineColor;
      box-sizing: content-box;

      &:after {
        content: '';
        position: absolute;
        display: none;

        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }

    .checkbox {
      border-radius: 20%;
      top: 2px;

      &:after {
        content: '\2713';
        position: absolute;
        display: none;

        top: -1px;
        left: 2px;
        font-size: 18px;
        font-weight: 600;
        color: white;
        font-family: sans-serif;
        border-radius: 50%;
        background-color: transparent;
      }
    }
  }
}

.slider {
  &::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
  }
  &::-moz-range-thumb {
    z-index: 2;
    position: relative;
  }
  &::-ms-thumb {
    z-index: 2;
    position: relative;
  }
}

.sliderLeftBar {
  max-width: 100%;
  display: block;
  background-color: #18af90;
  height: 8px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
}

.disableFilter {
  opacity: 0.6;
  pointer-events: none;
}
