// Landing styles
.hero-bg-image {
  position: absolute;

  display: flex;
  justify-content: flex-end;

  top: 0;
  right: 0;
  object-position: top right;
  object-fit: cover;

  transform: scale(1.2);
  transform-origin: top right;

  width: 33%;

  img {
  }
}

.header-section-text {
  max-width: 70%;
}
.header-section-ill {
  width: 150px !important;
}
.header-section-text-column {
  padding-left: 2rem;
}
