.quote {
  min-height: 7rem;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: visible;
  position: relative;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
