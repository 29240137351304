@font-face {
  font-family: 'CP-Avenir';
  src: url('../fonts/avenir-medium.woff') format('woff'),
    url('../fonts/avenir-medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CP-Avenir';
  src: url('../fonts/avenir-light.woff') format('woff'),
    url('../fonts/avenir-light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'CP-Avenir';
  src: url('../fonts/avenir-book.woff') format('woff'),
    url('../fonts/avenir-book.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CP-Avenir';
  src: url('../fonts/avenir-black.woff') format('woff'),
    url('../fonts/avenir-black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

$family-primary: 'CP-Avenir';
$family-secondary: 'CP-Avenir';
$weight-light: 200; // Light
$weight-normal: 400; // Medium
$weight-medium: 400; // Medium
$weight-semibold: 600; // Book
$weight-bold: 800; // Black

// Colors
$primary: #2c3e50;
$info: #2c3e50;
$success: #18af90;

$text: $primary;
$title-color: $primary;

$link: $primary;

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
button,
strong,
div {
  color: #2c3e50;
}

@import '../../node_modules/bulma/bulma.sass';

input {
  border-radius: none;
}
.input {
  border-radius: none;
  border: 1px solid #00000030 !important;
}

.is-active,
strong,
em,
.content h1,
.content h2,
.content h3 {
  color: $primary !important;
}

p {
  font-size: 1rem;
}

.button {
  border-radius: 0;

  &.is-light {
    font-weight: 500;
  }
}

em {
  & small {
    opacity: 0.5;
    font-size: 0.7rem;
  }
}
