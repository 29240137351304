.box {
  flex: none;
}

.section {
  padding: 0;
}

.scroll-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.fix-scrolling {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  height: auto;
  position: relative;
  padding: 0;

  &.columns {
    padding: 0;

    .box {
      padding: 0;
      position: relative;
      height: auto;
      margin-bottom: 1.5rem;
    }
  }
}

.box {
  position: relative;
}
