.card-open-content {
  padding: 1rem;
}

.guidelines {
  font-size: 0.8rem;
  color: #00000070;

  //flex-wrap: wrap;

  background-color: green;
}

.has-underline {
  text-decoration: underline;
}

.remove-underline {
  text-decoration: none;
}

.card-header:hover {
  cursor: pointer;
}

.card {
  user-select: none;
}

.circle-columns {
  justify-content: space-between;
  align-items: center;
}
.circle-column {
  display: flex;

  .level.content {
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  position: relative;

  h5,
  p {
    text-align: left;
    width: 100%;
    margin-left: 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 500;
  }

  h5 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.9rem;
    color: #00000080;
  }

  .level-left {
    flex: 1;
    height: 100px;

    position: relative;
  }
  .level-right {
    flex: 3;
    flex-direction: column;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #00000030;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.is-shadow {
  box-shadow: 0 3px 5px 0 #00000020;
}

.is-center-vertical {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}

span[data-tooltip] {
  border-bottom: none !important;
  user-select: none;
}

.speechCodeColumn {
  text-align: center;
  justify-content: center;
  flex: 1.25;
  padding-left: 0;
  padding-right: 0;
}
.speechCode {
  flex: 1.25;
  text-align: center;
  padding-left: 0;
  padding-right: 0;

  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 2.5em;
  line-height: 1em;

  border-radius: 0.25rem;

  &.color-green {
    background-color: #e1fff0;
    color: #246740 !important;
  }
  &.color-red {
    background-color: #ffd5d5;
    color: #730000;
  }
  &.color-yellow {
    background-color: #fff9da;
    color: #4b4001;
  }
  &.color-warning {
    background-color: #f9d5ff;
    color: #1d014b;
  }
}

.text-sort-rank {
  transition: 150ms;

  &:hover {
    cursor: pointer;
  }
  &:hover:not(.has-background-success) {
    color: #18af90;
  }
}
