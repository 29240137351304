.navbar-brand {
  .title {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.open-nav-container {
  position: fixed;
  background-color: white;
  z-index: 9999;
}

.navbar {
  transition: 200ms;
  height: auto;
}

.navbar-open {
  height: 100vh;

  z-index: 2000;
  top: 0;
  left: 0;
  z-index: 90000 !important;
  background-color: white;
}

.force-open-nav {
  display: block !important;
  box-shadow: none !important;
  a {
    text-align: center;
    font-size: 1.5rem;
  }
}

.logos {
  display: flex;
  height: 50px;
}
