.is-pointer {
  &:hover {
    cursor: pointer;
  }
}
.is-underlined {
  text-decoration: underline;
}
.is-rounded {
  border-radius: 6px;
  //overflow: hidden;
}
.is-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.has-background-liberal {
  background-color: #d1e3fb;
}
.has-background-conservative {
  background-color: #fbdada;
}
.has-background-administrative {
  background-color: #fcf3d8;
}
.is-mw-60 {
  max-width: 700px;
  width: 60%;
}
.is-dropdown:hover {
  cursor: pointer;
}
.is-center-vertical {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-align-left {
  justify-content: flex-start;
}
