//// Mobile ////
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .container {
    max-width: 95%;
  }
}

.header-section-text-column {
  @include until($desktop) {
    padding-left: 1rem;
    padding-right: 2rem;
  }
}
.header-section-text {
  @include until($desktop) {
    max-width: none !important;
  }
}
.header-section-ill {
  @include until($desktop) {
    width: 100%;
    margin-top: 1rem;
  }
}

.warning-logo {
  @include until($desktop) {
    max-width: none;
  }
}

.fix-scrolling {
  @include until($desktop) {
    width: 100% !important;
  }
}
.scroll-container {
  @include until($desktop) {
    padding: 0;
  }
}


@include until($desktop) {
  .rankings-list{
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .stat-column:nth-child(6){
    display: none!important;
  }
    
  
  .rank-column-item:nth-of-type(4){
    display: none!important;
  }
  .rank-column-item:nth-of-type(3){
    display: none!important;
  }
}

.stats-inline-label {
  @include from($desktop) {
    display: none;
  }
  @include until($desktop) {
    font-size: 0.6rem;
    display: block;
    height: 3em;
    line-height: 1.3em;
  }
}
.stat-column {
  @include until($desktop) {
    flex-shrink: 1;
    font-size: 0.8rem;
    padding: 0;
    display: block;
    order: 1;
  }
}
.ideoScore {
  font-weight: 900;
}
.speechCode,
.ideoScore {
  @include until($desktop) {
    order: 0;
    min-width: 30% !important;
    background-color: transparent !important;

    display: block;
    padding-top: 0 !important;
    .stats-inline-label {
      height: 1.5em;
    }
    margin-bottom: 1rem;
  }
}
.more-details {
  @include until($desktop) {
    display: none;
  }
}

.rank-column {
  @include from($desktop) {
    max-width: 65px;
    padding-left: 1rem;
  }
  @include until($desktop) {
    width: 13% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 1.5rem;
  }
}
.school-column {
  @include until($desktop) {
    flex: 1;
    order: 0;
    width: 57% !important;
    display: block;

    margin-bottom: 1rem;
  }
}
.mobile-school-detail-button {
  width: calc(100% - 2rem);
  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem;
  @include from($desktop) {
    display: none;
  }
}
.collapsed-school-details {
  @include until($desktop) {
    display: none !important;
  }
}
.card-header {
  position: relative;

  @include until($desktop) {
    box-shadow: none;
  }
}
.is-school-card {
  @include until($desktop) {
    margin-bottom: 1.5rem !important;
  }
}
.mobile-filter {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 1rem;
  margin-left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  button {
    width: calc(100vw - 4rem);
  }

  @include from($desktop) {
    display: none;
  }
}

// Mobile Nav
.nav-logos {
  @include until($desktop) {
    max-width: 100vw;

    * {
      transform: scale(0.8);

      margin-left: 0 !important;
    }
  }
}
.navbar-brand {
  @include until($desktop) {
    h1.title {
      font-size: 1.15rem !important;
    }
  }
}

.filter-mobile-container {
  .columns {
    margin: 0 !important;
  }
  .ranking-colummn {
    padding-bottom: 25vh;
  }
}

.filter-mobile {
  @include until($desktop) {
    z-index: 999;
    position: fixed;
    transition: 150ms;
    &.filter-mobile-show {
      display: block;

      top: 0;
      left: 0;
      width: 100% !important;
      max-height: 100vh;
      overflow-y: auto;

      margin: 0;
      padding: 0;
      margin-bottom: 0 !important;
      background-color: white;
      transform: translateY(0);
      opacity: 1;
    }
    &.filter-mobile-hide {
      //display: none !important;
      transform: translateY(100%);
      opacity: 0;
      pointer-events: none;
    }
    .sort-container {
      width: 100% !important;

      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 25vh;
      .level {
        display: flex !important;
      }
    }
  }
}

.no-mobile {
  @include until($desktop) {
    display: none !important;
  }
}
.only-mobile {
  @include from($desktop) {
    display: none !important;
  }
}

// School Overview
.school-overview-wrapper {
  @include until($desktop) {
    margin-top: 2rem;
    width: 100% !important;

    .level {
      display: flex;
      align-items: center;

      .level-right {
        margin-top: 0;
      }
    }
  }
}

.main-data-overview-column {
  @include until($desktop) {
    padding: 1.5rem !important;
    margin: 0 !important;
  }
}
.main-overview-columns {
  @include until($desktop) {
    margin: 0;
  }
}
.compare-button-detail {
  @include from($desktop) {
    margin-left: 1rem;
  }
}

// Spacing Helpers
.pl-6-desktop {
  @include from($desktop) {
    padding-left: 1.5rem;
  }
}
.px-4-touch {
  @include until($desktop) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

//landing
.mobile-companies {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@include until($desktop) {
  .no-flow {
    overflow-y: hidden;
  }

  .is-center-mobile {
    text-align: center;
  }

  .method-header {
    max-width: 100% !important;
    margin-left: 0;
  }

  .mobile-companies {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;

    img {
      width: calc(50% - 2rem) !important;
      height: auto !important;
      margin: 1rem;
    }
  }
  .approach {
    margin: 0;
    padding: 0;
    width: 100%;

    img {
      width: 90%;
    }

    .columns {
      margin: 0 !important;
      display: flex;

      @include until($desktop) {
        display: block;
      }
      flex-wrap: wrap;
      flex-direction: column;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      .is-one-third {
        order: 1;
        margin-bottom: 1rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      .is-two-thirds {
        order: 2;
        margin-bottom: 4rem !important;
      }
    }
    .column {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .mobile-container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .is-scroller {
    margin: 0 !important;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mobile-hero-image {
    width: auto;
    height: 320px;
  }

  .hero-body {
    .hero-button {
      margin-top: 1rem !important;
      margin-left: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .logos {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      a {
        width: auto;

        padding: 0 !important;
        margin: 0 !important;
      }

      .logo-cp {
        width: 120px;

        margin: 0 !important;
      }
      .logo-fire {
        width: 60px;

        margin: 0 !important;
        margin-top: -0.2rem !important;
      }
      .logo-real {
        width: 120px;

        margin: 0 !important;
        margin-top: -0.1rem !important;
      }
    }

    .title {
      font-size: 2rem;
    }

    .hero-text-content {
      max-width: 100%;
      width: 100%;
    }
  }
}

.no-focus {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.more-button {
  border: 0;
  background-color: transparent;
  opacity: 0.6;
  transition: 150ms;
  transform-origin: center;

  &:hover {
    opacity: 1;
    color: $success;
  }
}

.Select.is-open {
  z-index: 1000 !important;
}

@keyframes circle-animation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  35% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-80px);
  }
}
@keyframes circle-rankings-animation {
  0% {
  }
  15% {
  }
  23% {
    margin-left: -1.75rem;
    width: 100vw;
  }
  66% {
    margin-left: calc(-80px - 1.75rem);
    width: calc(100vw + 80px);
  }
  100% {
    width: 100vw;
    margin-left: -1.75rem;
  }
}

@include until($desktop) {
  .circle-anim {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #00000040;
    opacity: 0;

    // animation-name: circle-animation;
    // animation-delay: 500ms;
    // animation-duration: 2s;
    // animation-direction: normal;
    // animation-iteration-count: 1;

    position: absolute;
    top: 5px;
    right: 0;
    z-index: 2;
  }
  .rankings-list {
    width: 100vw;
    // animation-name: circle-rankings-animation;
    // animation-delay: 500ms;
    // animation-duration: 3s;
    // animation-direction: normal;
    // animation-iteration-count: 1;
  }

  .explore-columns {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 2rem !important;
  }

  .nComponent {
    flex-wrap: wrap;

    .level-right {
      width: 100%;
    }
  }

  .scroll-container {
    .fix-scrolling {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-y: hidden;

      .school-overview-wrapper {
        max-width: 80vw;
        &:first-child {
          margin-left: 2rem;
        }
      }
    }
  }
}
