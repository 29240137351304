.empty-text {
  background-color: #00000020;
  display: block;
  animation: widthRotate 1.5s ease alternate infinite;

  height: 1em;
  transform-origin: left;

  position: absolute;
  color: #2c3e50;
}

@keyframes widthRotate {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(0.8);
  }
  60% {
    transform: scaleX(0.9);
  }
  80% {
    transform: scaleX(0.7);
  }
  100% {
    transform: scaleX(1);
  }
}
